<template>
  <div class="content">
    <PageHeader :breadcrumbLinks="breadcrumbLinks" title="SMS" subtitle="Short Codes" />
    <section class="page-content container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card short-code-card">
            <div class="card-header">
              <div>
                <h2 class="card-title col">
                  <span class="material-symbols-outlined"> code </span>Short Codes
                </h2>
              </div>
              <div class="info-header">
                <div class="col-lg-10 search-column">
                <div class="filter-search">
                  <form @submit.prevent="search">
                    <div class="input-group">
                      <input
                        placeholder=""
                        type="text"
                        class="form-control"
                        :v-model="searchQuery"
                        ref="searchInput"
                      />
                      <div class="input-group-prepend">
                        <button
                          type="button"
                          class="btn icon_btn"
                          @click="search"
                        >
                          <span class="material-symbols-outlined">
                            search
                          </span>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
                <span class="material-icons title filter"> swap_vert </span>
              </div>
                <!-- <a
                  >Adicionar novo short code
                  <span class="material-symbols-outlined add-circle"> add_circle </span>
                </a>
                <span class="material-symbols-outlined"> download </span>
                <span class="material-symbols-outlined"> delete </span> -->
              </div>
            </div>
          </div>
          <div>
            <TableComponent class="custom-table" :fields="fields" :items="shortCodes">
              <template #cell(status)="slotProps">
                <span
                  class="icon-success"
                  v-if="slotProps.item.data.status == 'active'"
                  ><span class="status-circle"></span>  Ativo</span
                >
                <span class="icon-failed" v-else
                  >Inativo</span
                >
              </template>
              <template #cell(alias)="slotProps">
                {{ slotProps.item.data.alias }}
              </template>
              <template #cell(actions)="slotProps">
                <router-link :to="`/chat/reports/history/${slotProps.item.id}`">
                  <span class="material-symbols-outlined">search</span>
                </router-link>
              </template>
            </TableComponent>
            <br />
            <pagination :totalPages="pages" v-model="form.page" @change="fetch" />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import PageHeader from '@/components/rebranding/PageHeader.vue';
import SmsService from '@/services/sms.service';
import Pagination from '@/components/PaginationComponent.vue';
import TableComponent from '@/components/TableComponent.vue';

export default {
  name: 'SmsShortCodes',
  components: {
    PageHeader,
    Pagination,
    TableComponent,
  },
  data() {
    return {
      fields: ['name', 'alias', 'status'],
      breadcrumbLinks: {
        1: '/sms/reports/stat',
        2: '/sms/short-codes',
      },

      fetched: false,
      searchQuery: null,
      shortCodes: [],
      form: {
        name: '',
        page: 1,
      },
      pages: 1,
      isSending: false,
      selectedShortCodes: [],
    };
  },
  computed: {
    selectAllBlacklists: {
      get() {
        if (this.shortCodes) {
          return this.selectedShortCodes.length === this.shortCodes.length;
        }
        return false;
      },
      set(value) {
        const selected = [];

        if (value) {
          this.shortCodes.forEach((client) => {
            selected.push(client);
          });
        }

        this.selectedShortCodes = selected;
      },
    },
  },
  created() {
    this.fetch();
  },
  methods: {
    search() {
      // this.form.page = page;
      this.fetched = false;
      const word = this.$refs.searchInput.value;
      let result = null;
      SmsService.getAccountShortCodes(this.form)
        .then(
          (response) => {
            this.shortCodes = response.data;
            result = this.shortCodes.filter((item) => word
              .toLowerCase()
              .split(' ')
              .every((v) => item.data.alias.toLowerCase().includes(v)));
            this.shortCodes = result;
            // console.log('Teste: ', this.shortCodes);
            // this.pages = response.last_page;
            this.fetched = true;
          },
          (error) => {
            this.$toast.show({
              title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code}`,
              content: error.message,
              type: 'danger',
            });
          },
        )
        .finally(() => {
          this.fetched = true;
        });
    },
    fetch(page = 1) {
      this.form.page = page;
      this.fetched = false;
      SmsService.getAccountShortCodes(this.form)
        .then(
          (response) => {
            this.shortCodes = response.data;
            // console.log('Teste: ', this.shortCodes);
            this.pages = response.last_page;
          },
          (error) => {
            this.$toast.show({
              title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code}`,
              content: error.message,
              type: 'danger',
            });
          },
        )
        .finally(() => {
          this.fetched = true;
        });
    },
  },
  selectedBlacklistsChange(param) {
    this.selectedShortCodes = param.selectedShortCodes;
  },
};
</script>
<style scoped lang="scss">
.btn-remove {
  color: #fff;
  margin-bottom: 5px;
}
.btn-new {
  margin-bottom: 5px;
}
.color-white {
  color: #fff;
}
.form-search {
  float: right;
  width: 100%;
}
.form-search button {
  border: solid 1px #ccc;
  border-left: 0px;
  text-align: center;
  border-radius: 0px 10px 10px 0px;
  height: 35px;
}
.form-search button i {
  font-size: 16px;
}
.w-100 {
  width: 100% !important;
}

h2 {
  font-size: 16px !important;
  color: #667085;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

/* Rebranding */
.short-code-card .card-header {
  display: flex;
  gap: 10px;
  padding: 15px 20px 15px 0 !important;
  border-bottom: 3px solid rgba(107, 35, 220, 0.2);
  justify-content: space-between;
  align-items: center;
}

.info-header{
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 1rem;
}

.info-header a,
.info-header .add-circle {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--clr-yup-purple);
  cursor: pointer;
  padding: 0 1rem 0 0;
}

.info-header span {
  color: #667085;
}

.more-options {
  cursor: pointer;
  color: #667085;
  font-size: 24px;
  display: inline-block;
}

.info-header a,
span:hover {
  color: var(--clr-yup-purple-hover);
}

.short-code-card .card-title {
  font-weight: 400;
  font-size: 1.25rem;
}

.short-code-table th,
.short-code-table td {
  text-align: center;
  vertical-align: middle;
}

.short-code-table td,
.short-code-table th,
.short-code-table thead th {
  border-bottom: 1px solid transparent;
}

.short-code-table .control-group.opt-2 {
  width: fit-content;
  margin: unset;
  float: left;
}

.short-code-table tr th:first-child,
.short-code-table tr td:first-child {
  text-align: right;
  display: flex;
  align-items: center;
  gap: 10px;
}

::v-deep .table-container table thead tr th {
  width: 100%;
}

.rebranding .card {
  box-shadow: none !important;
}

.card-title {
  padding: 0 !important;
}

@media (min-width: 768px) {
  .short-code-table tr th:first-child,
  .short-code-table tr td:first-child {
    text-align: left;
    padding-left: 2rem;
  }
}

@media (max-width: 767.98px) {
  .short-code-table tr td:first-child::before {
    margin-right: auto;
  }
}

.short-code-table td {
  border-top: transparent;
}

.short-code-table button {
  height: auto;
}

.icon-success {
  background-color: #5a99ec1f;
  color: #5A99EC !important;
  font-size: 12px !important;
  padding: 3px 6px;
  border-radius: 3px;
}

.icon-failed {
  background-color: #ff000028;
  color: #ff0000 !important;
  font-size: 12px !important;
  padding: 3px 6px;
  border-radius: 3px;
}

.status-circle {
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: #4299E1;
  border-radius: 30%;
  margin-right: 5px;
}

.status-circle-failed {
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: #ff0000;
  border-radius: 30%;
  margin-right: 5px;
}

.filter-search {
  max-width: 600px;
  width: inherit;

  input {
    border-right-color: var(--form-input) !important;
  }
}

.filter-search .btn {
  padding: 0;
}

.filter-search ~ .input-group {
  justify-content: flex-end;
}

.icon_btn {
  display: flex;
  align-items: center;
  background-color: #fff;
  border: 1px solid var(--form-border);
  border-left-color: var(--form-input);
  border-radius: 0.25rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding-right: 15px !important;

  span {
    color: var(--gray-font-color);
  }
}

.darkmode .icon_btn {
  background-color: var(--form-input);
}

.search-column {
  display: flex;
  align-items: center;
  gap: 10px;

  span.filter {
    font-size: 1.5rem;
    transition: 0.5s;
    padding: 9px;
    cursor: pointer;
  }
}

.search-column span:hover:not(.search-column .filter-search span) {
  background-color: var(--background-icon-hover);
  border-radius: 5px;
}
</style>
